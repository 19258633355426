@import 'css/variables';
@import 'css/functions';
@import 'css/mixins';

@function color-focus-border($color) {
    @return mix(#000, $color, 30%);
}

@function color-focus-outline($color) {
    @return mix(#fff, $color, 70%);
}

@mixin button-100 {
    @include text-size-100;
    height: $control-100-height;
    line-height: $control-100-height - 2px; //border size
    padding: 0 $spacing-50;
}

@mixin button-200 {
    @include text-size-200;
    height: $control-200-height;
    line-height: $control-200-height - 2px; //border size
    padding: 0 $spacing-100;
}

@mixin button-300 {
    @include text-size-300;
    height: $control-300-height;
    line-height: $control-300-height - 2px; //border size
    padding: 0 $spacing-200;
}

@mixin button-primary {
    color: $light-100;
    background-color: $identity-200;
    border-radius: 22px;
    font-weight: 500;

    &:hover {
        background-color: color-hover($identity-200);
        opacity: 0.65;
        cursor: pointer;
    }

    &:active,
    &.rods-button--active,
    &:focus,
    &.rods-button--focus {
        outline: 0;
        border-color: color-focus-border($identity-200);
        box-shadow: 0 0 0 0.2rem color-focus-outline($identity-200);
        opacity: 0.65;
    }

    &:active,
    &.rods-button--active {
        background-color: color-active($identity-200);
    }

    &[disabled],
    &:disabled {
        opacity: 0.39;
        border-color: color-disable($identity-200);
        color: color-disable($identity-200);
    }
}

@mixin button-secondary {
    color: $identity-200;
    border: 1px solid $identity-200;
    background-color: $light-100;
    border-radius: 22px;
    font-weight: 500;

    &:hover {
        color: color-hover($bermuda-gray);
        border-color: color-hover($bermuda-gray);
        cursor: pointer;
        opacity: 0.65;
    }

    &:active,
    &.rods-button--active,
    &:focus,
    &.rods-button--focus {
        outline: 0;
        border-color: color-focus-border($identity-200);
        box-shadow: 0 0 0 0.2rem color-focus-outline($identity-200);
        opacity: 0.65;
    }

    &:active,
    &.rods-button--active {
        border-color: color-active($identity-200);
    }

    &[disabled],
    &:disabled {
        background-color: transparent;
        border-color: color-disable($identity-200);
        color: color-disable($identity-200);
        opacity: 0.39;
    }
}

