@import "css/variables";

@mixin text-size-100() {
    font-size: $text-100-font-size;
    line-height: $text-100-line-height;
}

@mixin text-size-200() {
    font-size: $text-200-font-size;
    line-height: $text-200-line-height;
}

@mixin text-size-300() {
    font-size: $text-300-font-size;
    line-height: $text-300-line-height;
}

@mixin text-200() {
    @include text-size-200;
    color: $text-200-color;
}

@mixin text-300() {
    @include text-size-300;
    color: $text-300-color;
}

@mixin is-invalid() {
    &.is-invalid {
        border-color: $color-critical;
        box-shadow: 0px 0px 0px 3px rgba(206, 0, 55,0.11559999999998305);
    }
}

@mixin input-border() {
    transition: border-color .15s ease-in-out;
    border: 1px solid $light-400;
    border-radius: $border-radius-2;
    box-shadow: none;
}

@mixin input-border-focus() {
    border-color: $bermuda-gray;
    outline: 0;
    box-shadow: 0px 0px 0px 3px rgba(26,27,28,0.11559999999998305);
}

@mixin input-border-hover() {
    border-color: color-hover($bermuda-gray);
    cursor: pointer;
}

@mixin link() {
    color: $color-link;
    transition: color .15s ease-in-out;

    &:hover {
        text-decoration: none;
        color: mix(#fff, $color-link, 50%);
    }

    &:focus {
        color: color-focus($color-link);
    }

    &:active {
        color: color-active($color-link);
    }

    &:disabled {
        color: color-disable($color-link)
    }
}



