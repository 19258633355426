@import 'css/variables';
@import 'css/mixins';

select.rods-form-control {
    appearance: none;
    background-repeat: no-repeat, repeat;
    background-size: $input-select-icon-size auto, 100%;
    background-position: right $input-select-icon-size center, 0 0;
    background-image: $input-select-icon, linear-gradient(to bottom, $light-100, $light-200);

    &:disabled,
    &[readonly] {
            background-color: $input-disabled-bg;
            background-image: $input-select-icon;
        }

    // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
        display: none;
    }

    &::-ms-value {
        // For visual consistency with other platforms/browsers,
        // suppress the default white text on blue background highlight given to
        // the selected option text when the (still closed) <select> receives focus
        // in IE and (under certain conditions) Edge.
        // See https://github.com/twbs/bootstrap/issues/19398.
        color: $input-color;
        background-color: $input-bg;
    }
}
