@import "css/functions";

$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
$font-size-base: 15px;

$identity-200: #142e7b;
$identity-202: #E2E6EE;
$identity-500: #CE0037;

$bermuda-gray: #6c7aaa;
$pale-sky: #6f7783;

$light-100: #FFFFFF;
$light-200: #F6F8FB;
$light-300: #DEE5EF;
$light-400: #CBD2DB;
$light-500: #898D92;
$light-600: #565656;
$light-700: #000000;

$color-critical: $identity-500;

$color-link: $light-700;  ;

$spacing-50: 6px;
$spacing-100: 13px;
$spacing-200: 26px;
$spacing-400: 65px;

$border-radius-2: 2px;
$border-radius-3: 3px;
$border-radius-4: 4px;
$border-radius-5: 5px;
$border-radius-6: 6px;

$text-100-font-size: 0.733rem;
$text-100-line-height: 1.5;
$text-100-color: $light-600;

$text-200-font-size: 0.833rem;
$text-200-line-height: 1.4;
$text-200-color: $light-600;

$text-300-font-size: 0.933rem;
$text-300-line-height: 1.4;
$text-300-color: $light-600;

$text-400-font-size: 1rem;
$text-500-font-size: 1.25rem;
$text-600-font-size: 1.50rem;

$control-100-height: 18px;
$control-200-height: 36px;
$control-300-height: 44px;

$input-color:               $light-700;
$input-bg:                  $light-100;
$input-disabled-bg:         $light-300;
$input-font-size:           $text-400-font-size;
$input-line-height:         $text-200-line-height;
$input-select-icon:         url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' width='448' height='512'%3E%3Cpath d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'/%3E%3C/svg%3E");
$input-select-icon-size:    8px;
$input-height-switch:           20px;
$input-width-switch:            40px;
$input-switch-indicator-size:   12px;
$input-check-size:          1.067rem;
$input-check-margin:        0.733rem;


:export {
    identity200: $identity-200;
    identity202 : $identity-202;
    identity500: $identity-500;

    light100: $light-100;
    light200: $light-200;
    light300: $light-300;
    light400: $light-400;
    light500: $light-500;
    light600: $light-600;
    light700: $light-700;

    bermuda-gray: $bermuda-gray;
    pale-sky: $pale-sky;

    colorCritical: $color-critical;

    spacing50: $spacing-50;
    spacing100: $spacing-100;
    spacing200: $spacing-200;
    spacing400: $spacing-400;

    borderRadius2: $border-radius-2;
    borderRadius3: $border-radius-3;
    borderRadius4: $border-radius-4;
    borderRadius5: $border-radius-5;
    borderRadius6: $border-radius-6;

    text100FontSize: $text-100-font-size;
    text100LineHeight: $text-100-line-height;
    text100Color: $text-100-color;

    text200FontSize: $text-200-font-size;
    text200LineHeight: $text-200-line-height;
    text200Color: $text-200-color;

    text300FontSize: $text-300-font-size;
    text300LineHeight: $text-300-line-height;
    text300Color: $text-300-color;

    text400FontSize: $text-400-font-size;
    text500FontSize: $text-500-font-size;
    text600FontSize: $text-600-font-size;

    inputColor: $input-color;
    inputBg: $input-bg;
    inputDisabledBg: $input-disabled-bg;
    inputFontSize: $input-font-size;
    inputLineHeight: $input-line-height;
    control100Height: $control-100-height;
    control200Height: $control-200-height;
    control300Height: $control-300-height;
}

