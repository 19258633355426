@import "css/variables";
@import "css/mixins";

// This mixin is used to create custom checkboxes and radios.
// It adds CSS styles to the the class $name, then select '$name .rods-check-input__input'
// and '$name .rods-check-input__label' to hide the input, position the label, create a false check area etc.
// See RadioInput and CheckboxInput.
@mixin check-input($name, $icon-size: $input-check-size, $icon-gutter: $input-check-margin) {
    .#{$name} {
        @include text-300();
        display: flex;
        align-items: flex-start;
        margin-right: $spacing-100;
        min-height: $input-font-size * $input-line-height;
        outline: 0;

        .rods-check-input__input {
            z-index: -1; // Put the input behind the label so it doesn't overlay text
            opacity: 0;
            top: 0;
            left: 0;

            &:checked ~ .rods-check-input__label::before {
                color: $identity-200;
            }

            &:focus,
            &:active:not(:disabled) {
                ~ .rods-check-input__label::before {
                    border-color: $identity-200;
                }
            }

            &:disabled ~ .rods-check-input__label {
                cursor: not-allowed;

                &::before {
                    background-color: $input-disabled-bg;
                }
            }
        }

        .rods-check-input__label {
            margin-bottom: 0; // Override default `<label>` bottom margin
            cursor: pointer;
            position: relative;
            display: block;

            // Background-color
            &::before {
                position: absolute;
                top: 0;
                left: -($icon-gutter + $icon-size);
                display: block;
                width: $icon-size;
                height: $icon-size;
                pointer-events: none;
                content: "";
                user-select: none;
                @include input-border();
            }

            // Foreground (icon)
            &::after {
                @include text-200();
                position: absolute;
                top: 0;
                left: -($icon-gutter + $icon-size);
                width: $icon-size;
                height: $icon-size;
                display: block;
                content: "";
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 100% 100%;
            }
        }

        &.rods-check-input--no-label {

            .rods-check-input__label {
                &::before {
                    left: -$icon-size;
                }

                &::after {
                    left: -$icon-size;
                }
            }
        }

        &.rods-check-input--is-invalid .rods-check-input__label {
            color: $color-critical;

            &::before {
                border-color: $color-critical !important;
            }
        }
    }
}
