@import 'css/variables';
@import 'css/mixins';
@import 'css/functions';
@import './button';

/* Button variants and sizes */
.rods-button--primary {
    @include button-primary;
}

.rods-button--secondary {
    @include button-secondary;
}

.rods-button--100 {
    @include button-100;
}

.rods-button--200 {
    @include button-200;
}

.rods-button--300 {
    @include button-300;
}

.rods-button--link {
    @include link;
    display: inline;
}


