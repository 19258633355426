@import "css/variables";
@import "css/mixins";

.rods-step-tracker {
    display: flex;
    align-items: center;
    overflow-x: auto;

    &.rods-step-tracker--large {
        align-items: flex-start;
    }
}

.rods-step-tracker__step-root {
    flex: 0 0 auto;
    position: relative;

    .rods-step-tracker--large & {
        flex: 1;
    }
}

.rods-step-tracker__connector {
    flex: 1 1 auto;
    background-color: $light-400;
    height: 1px;

    .rods-step-tracker--large & {
        position: absolute;
        top: 9px;
        left: calc(-50% + #{$spacing-100});
        right: calc(50% + #{$spacing-100});
    }
}

.rods-step-tracker__connector-line {
    background-color: $identity-200;
    width: 0;
    height: 1px;
}

.rods-step-tracker__step {
    display: flex;
    align-items: center;
    padding: 0 $spacing-50;

    .rods-step-tracker--large & {
        width: 100%;
        min-width: 115px;
        flex-direction: column;
        justify-content: center;
        padding: 0;
    }
}

.rods-step-tracker__title {
    @include text-200;
    margin-top: 0;
    color: $light-500;

    .rods-step-tracker--large & {
        @include text-300;
        margin-top: $spacing-50;
    }

    .rods-step-tracker__step--active &,
    .rods-step-tracker__step--completed & {
        color: $light-700;
    }

}

.rods-step-tracker__step-number {
    @include text-200;
    display: flex;
    justify-content: center;
    align-items: center;
    height: $text-200-font-size * $text-200-line-height;
}

.rods-step-tracker__step-number-text {
    color: $light-500;


    .rods-step-tracker__step--active & {
        color: $identity-200;
        margin-right: $spacing-50;

        .rods-step-tracker--large & {
            color: $light-700;
            margin-right: 0;
        }
    }
}

.rods-button.rods-step-tracker__step-number-text:hover {
    color: $light-700;
}

.rods-step-tracker__completed-icon {
    fill: $identity-200;
    width: 12px;
    height: 12px;
}

// Clickable
.rods-button.rods-step-tracker__step-number,
.rods-button.rods-step-tracker__title {
    &:hover, &:active, &:focus {
        color: $light-700;
        background-color: inherit;
        text-decoration: none;
    }
}
