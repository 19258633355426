@import "css/variables";
@import "css/mixins";
@import "css/functions";
@import "components/inputs/CheckInput/check_input.scss";

@mixin checkbox-background-image($color) {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='#{$color}' d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/%3E%3C/svg%3E"), "#", "%23");
}

@include check-input('rods-checkbox-input');

.rods-checkbox-input {
    .rods-check-input__input:checked ~ .rods-check-input__label::after {
        @include checkbox-background-image($light-100);
        background-size: 14px;
        background-color: $identity-200;
        border-radius: $border-radius-4;
    }

    .rods-check-input__input:checked:disabled ~ .rods-check-input__label::after {
        @include checkbox-background-image($light-100);
    }

    &.rods-check-input--is-invalid .rods-check-input__input:checked ~ .rods-check-input__label::after {
        @include checkbox-background-image($color-critical);
    }

    .rods-check-input__label::before {
        border-radius: $border-radius-4;
    }
}



