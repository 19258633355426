@import "css/mixins";
@import "css/variables";
@import "components/Button/button";

// We enforce viewport width: device-width for IE and Edge However, it causes the scrollbar to become semi-transparent
// and overlaps the body content. The following rule make sure the scrollbar is always visible.
html {
    -ms-overflow-style: scrollbar;
    font-family: $font-family-base;
    font-size: $text-200-font-size;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.rods-link {
    @include link;
}

// Trial CSS related to display

.container {
    margin: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.row {
    position: relative;
    width: 50%;
    height: 100%;
    display: flex;
    background-color: white;
}

.rods-button-loading {
    position: relative;
}

.spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.spinner-secondary {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.spinner-loading {
    display: none;
}

.loading {
    opacity: 0%;
}

a {
    text-decoration: none;
    color: $identity-200;
}

// TrialImagesBox CSS

.rods-images-container {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 745px;
    display: grid;
    grid-template-columns: repeat(100, 1fr);
    grid-template-rows: repeat(100, 1fr);
}

.rods-logobox {
    background-color: #142e7b;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100;
    grid-column: 1 / 70;
    grid-row: 1 / 40;
    min-width: 440px;
}

.rods-logobox-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: 12px;
}

.rods-image-histo {
    border: 1.5rem solid white;
    z-index: 200;
    border-radius: 3%;
    background-color: white;
    box-shadow: 0px 6px 13px rgba(0, 0, 0, 0.13);
    grid-column: 64 / span 22;
    grid-row: 29 / span 22;
    display: block;
    object-fit: cover;
    width: 100%;
    max-height: 100%;
}

.rods-image-intro {
    z-index: 1;
    grid-column: 13 / span 70;
    grid-row: 34 / span 62;
    display: block;
    object-fit: cover;
    width: 100%;
    max-height: 100%;
}

.rods-image-data {
    border: 0.3rem solid white;
    border-radius: 3%;
    z-index: 10;
    background-color: white;
    box-shadow: 0px 6px 13px rgba(0, 0, 0, 0.13);
    grid-column: 4 / span 40;
    grid-row: 78 / span 20;
    display: block;
    object-fit: contain;
    width: 100%;
    max-height: 100%;
}

.rods-logo {
    width: 50%;
    min-width: 10px;
    max-width: 500px;
    margin-top: 0.5rem;
}

.rods-text {
    width: 340px;
    height: auto;
    font-size: 2.25rem;
    font-weight: 700;
    color: #ffffff;
    line-height: 50px;
    margin-top: 2rem;
}

.rods-greenbutton {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    width: 65%;
    height: auto;
    padding: 12px;
    border-radius: 6px;
    background-color: #d0f0eb;
    background-size: cover;
    font-size: $text-300-font-size;
    font-weight: 700;
    color: #01473f;
    font-style: italic;
    line-height: 23px;
    text-align: center;
}

// TrialSignUpForm CSS

.rods-form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60%;
    margin: auto;
}

.rods-form-container > *:first-child {
    align-self: flex-end;
}

.rods-lang-selector-area {
    display: flex;
    justify-content: flex-end;
    margin-bottom: $spacing-200;
}

.rods-lang-selector-box,
.rods-lang-selector-box-selected {
    margin: 5%;
    color: $pale-sky;
    cursor: pointer;
    border: none;
    background: none;
    padding: 1px;
}

.rods-lang-selector-box-selected {
    color: $identity-200;
    font-weight: 500;
    cursor: pointer;
}

.rods-platform-name-preview-ok {
    padding: 9px 13px;
    margin-top: 6px;
    border-radius: 6px;
    background-color: rgba(75, 196, 103, 0.26);
    background-size: cover;
    font-size: $text-200-font-size;
    color: #0d471b;
    line-height: 18px;
}

.rods-textinput-helptext {
    padding-top: 0.5rem;
    font-size: $text-200-font-size;
    color: #6f7783;
}

.rods-checkInput-group {
    display: flex;
}

// Email Validation and Error Text

.rods-text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 60%;
    margin: auto;
    margin-bottom: 30%;
}

.rods-text-container h3 {
    font-family: Helvetica, sans-serif;
    font-size: $text-600-font-size;
}

.rods-text-container p {
    color: $light-600;
    font-family: Helvetica, sans-serif;
    font-size: $text-500-font-size;
    margin-bottom: 2rem;
}

.rods-text-icon {
    width: 8%;
    margin-bottom: 1rem;
}

// Trial information container

.rods-info-container {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    margin-bottom: 4rem;
    margin-top: 4rem;
    border-radius: 6px;
    outline: 0;
    -moz-box-shadow: 0 0 4px #ccc;
    -webkit-box-shadow: 0 0 4px #ccc;
    box-shadow: 0 0 4px #ccc;
    background-size: cover;
    font-size: $text-300-font-size;
    background-color: #f2f3f8;
    line-height: 18px;
    padding: 2rem;
    flex-wrap: nowrap;
}

.rods-info-last-child {
    margin-bottom: 2rem;
    margin: auto;
}

.rods-invalid-info-last-child {
    margin-bottom: 2rem;
    color: #565656;
    font-size: $text-500-font-size;
}

.rods-info-create {
    display: none;
}

// Trial Profile Page

.rods-profile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    margin: auto;
    margin-top: 25px;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 3rem;
}

.rods-profile-container p {
    margin: 0;
    padding: 0;
    text-align: center;
    margin-bottom: 1rem;
}

.rods-profile-container .rods-form-fieldset {
    margin: 0.8rem;
}

.rods-profile-form > *:first-child {
    margin: 0.8rem;
}

.rods-profile-form {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 90%;
}

.rods-profile-form > *:last-child {
    align-self: flex-end;
}

.rods-profile-form .rods-button--secondary {
    margin-right: 6px;
}
.rods-profile-steptracker {
    margin-top: 2rem;
}

// Responsive CSS on small devices

@media (max-width: 1145px) {
    .container {
        margin: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .row {
        width: 100%;
    }
    .rods-image-intro,
    .rods-image-data,
    .rods-image-histo {
        display: none;
    }
    .rods-images-container {
        position: relative;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);
        margin: auto;
        width: 60%;
        min-width: 100%;
        min-height: 0;
    }

    .rods-logobox {
        padding: 1rem;
        min-width: 480px;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .rods-logobox-content {
        margin-left: 2rem;
    }

    .rods-form-container {
        width: 70%;
        margin-top: 10%;
        margin-bottom: 10%;
    }

    .rods-profile-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80%;
        margin: auto;
        margin-top: 25px;
        background-color: #ffffff;
        border-radius: 20px;
        padding: 3rem;
    }

    .rods-profile-container h1 {
        text-align: center;
    }

    .rods-text-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 60%;
        width: 80%;
        margin: auto;
        margin-bottom: 30%;
        margin-top: 2rem;
    }

    .rods-info-container p {
        font-size: $text-200-font-size;
    }
}

// Responsive CSS on medium devices

@media only screen and (min-width: 663px) and (max-width: 1146px) {
    .rods-logobox {
        padding: 1rem;
        min-width: 480px;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .rods-logobox-content {
        margin-left: 18%;
    }

    .rods-text-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 60%;
        width: 60%;
        margin: auto;
        margin-bottom: 30%;
        margin-top: 2rem;
    }

    .rods-info-container p {
        font-size: $text-300-font-size;
    }
}

@media only screen and (min-width: 1030px) and (max-width: 1578px) {
    .rods-profile-container {
        width: 40%;
    }

    .rods-text-container {
        width: 60%;
    }
    .rods-text-container button {
        @include button-300;
    }
}

@media only screen and (min-width: 1146px) and (max-width: 1660px) {
    .rods-text-container {
        width: 70%;
    }
}

@import "./forms";
