@import "css/mixins";
@import "css/variables";

.rods-form-control {
    display: block;
    width: 100%;
    height: $control-200-height;
    font-size: $input-font-size;
    line-height: $control-200-height;
    font-family: inherit;
    margin-top: $spacing-100;
    padding: 0 $spacing-100;
    @include input-border();
    border-radius: $border-radius-4;
    background-color: $input-bg;
    color: $light-600;

    &:hover {
        @include input-border-hover();
    }

    &:focus {
        @include input-border-focus();
    }

    &::placeholder {
        color: $light-500;
    }

    &:disabled,
    &[readonly] {
        @include input-border();
        background-color: $input-disabled-bg;
    }

    &:disabled {
        cursor: not-allowed;
    }

    @include is-invalid();

    &::-ms-clear {
        display: none;
    }
}

.rods-form-fieldset {
    padding: 0;
    margin-bottom: $spacing-200;
}

.rods-form-fieldset:last-child {
    margin-bottom: 0;
}

@media (max-width: 992px) {
    .rods-form-fieldset:first-child {
        margin-top: $spacing-200;
    }
    .rods-form-fieldset:last-child {
        margin-bottom: $spacing-200;
    }
}



.rods-form h2 {
    margin-bottom: $spacing-200;
}

.rods-form h3 {
    margin-bottom: $spacing-100;
}

.rods-form--inline {
    display: flex;
}

.rods-form--inline > .rods-form-group {
    flex: 1 1 auto;
}

.error {
    color: red;
    font-size: $input-font-size;
    line-height: $control-100-height;
}
